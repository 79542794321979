import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { ClockIcon } from '@quno/patient-journey/src/components/CalendarBooking/icons/Clock';
import styles from '@quno/patient-journey/src/components/CalendarBooking/SingleInformationSection/SingleInformationSection.module.scss';
import { InformationIcon } from '@quno/patient-journey/src/components/CalendarBooking/SingleInformationSection/InformationIcon';
import type { BookingAvalabilityOptionsSuccess } from '@quno/patient-journey/types/BookingAvalabilityOptions';

type SingleInformationSectionProps = {
  information?: BookingAvalabilityOptionsSuccess['information'];
  selectedDoctor?: string;
};

export const SingleInformationSection = ({
  information,
  selectedDoctor,
}: SingleInformationSectionProps): JSX.Element => {
  const { t } = useTranslation(true);
  const { durationMinutes, clinicAddress, clinicName, meetingType } =
    information || {};

  const doctor = information?.doctors?.find(
    (doctor) => !doctor.isAnyDoctor && doctor.sub === selectedDoctor,
  );

  const titles = [doctor?.title?.trim(), doctor?.postTitle?.trim()]
    .filter(Boolean)
    .join(', ');

  return (
    <div className={styles.information}>
      <div className={styles.doctor}>
        {!doctor?.name ? (
          <Skeleton
            circle
            width={104}
            height={104}
            style={{ marginBottom: 10 }}
          />
        ) : (
          <Image
            loader={createCloudimgLoader()}
            src={doctor.picture || '/v2/images/doctor-placeholder.jpg'}
            alt={`${doctor.name} avatar`}
            width={108}
            height={108}
          />
        )}

        <p>
          <b>{!doctor?.name ? <Skeleton /> : doctor.name}</b>
        </p>
        <p>{!doctor?.name ? <Skeleton /> : titles}</p>
      </div>

      <div className={styles.duration}>
        <div className={styles.item}>
          {!meetingType ? (
            <Skeleton />
          ) : (
            <>
              <InformationIcon meetingType={meetingType!} />{' '}
              {t(`calendar-booking.type.${meetingType}`)}
            </>
          )}
        </div>
        {information?.dayPrecision === 'time' && (
          <>
            <div className={styles.separator} />
            <div className={styles.item}>
              {!durationMinutes ? (
                <Skeleton />
              ) : (
                <>
                  <ClockIcon />
                  {`${durationMinutes} ${t('calendar-booking.minutes')}`}
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div className={styles.clinic}>
        <p>
          <b className={styles.name}>
            {!clinicName ? <Skeleton /> : clinicName}
          </b>
        </p>

        {clinicAddress && <p className={styles.address}>{clinicAddress}</p>}

        {!clinicName && (
          <p className={styles.address}>
            <Skeleton />
          </p>
        )}
      </div>
    </div>
  );
};
